@import "../../../styles/globle.scss";

.imageAndText {
  margin: 100px 0;
  @media (max-width: 1440px) {
    margin: 90px 0;
  }
  @media (max-width: 425px) {
    margin: 53px 0;
  }
  .blogPageImage {
    width: 100%;
    // height: 550px;
    object-fit: contain;
    // @media (max-width: 1024px) {
    //   height: 400px;
    // }
    // @media (max-width: 834px) {
    //   height: 320px;
    // }
    // @media (max-width: 425px) {
    //   height: 164px;
    // }
  }
  .headingQue {
    color: $blackColor;
    margin-top: 30px;
    font-family: 700;
    font-size: 34px;
    margin-bottom: 12px;
    line-height: 44px;
    @media (max-width: 1024px) {
      font-size: 30px;
    }
    @media (max-width: 834px) {
      font-size: 28px;
    }
    @media (max-width: 425px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  .authorAndDate {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    font-family: $extra-font;
    margin-bottom: 40px;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  .otherText {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      font-family: $extra-font;
      margin-bottom: 20px;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 14px;
      }
      @media (max-width: 834px) {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 12px;
      }
    }
    h3 {
      font-weight: 700;
      font-size: 18px;
      font-family: $extra-font;
      margin-bottom: 20px;
      @media (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 14px;
      }
      @media (max-width: 834px) {
        font-size: 14px;
        margin-bottom: 12px;
      }
    }
    .decMarBottom {
      margin-bottom: 5px;
    }
    .innerPtag {
      margin-left: 5px;
      ul {
        margin-left: 20px;
        li:not(:last-child) {
          p {
            margin-bottom: 0px;
          }
        }
      }
      p:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .imagDiv {
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 1024px) {
        margin-bottom: 14px;
      }
      @media (max-width: 834px) {
        margin-bottom: 12px;
      }
      img {
        @media (max-width: 834px) {
          width: 100%;
        }
      }
    }
    .threeImagDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 29.5%;
      }
    }
    .mobileimagDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40%;
        @media (max-width: 1024px) {
          width: 40%;
        }
        @media (max-width: 834px) {
          width: 60%;
        }
        @media (max-width: 425px) {
          width: 100%;
        }
      }
    }
    .twoImagDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 29.5%;
      }
    }
    .appLinkDiv {
      margin-bottom: 20px;
      a {
        border: 1px solid $placeHolderTextColor;
        display: flex;
        text-decoration: none;
        .appLeftSide {
          width: 70%;
          padding: 20px;
          @media (max-width: 425px) {
            width: 66%;
            padding: 10px;
          }
          h5 {
            font-family: 700;
            font-size: 18px;
            margin-bottom: 12px;
            color: $blackColor;
            @media (max-width: 425px) {
              font-size: 16px;
            }
            @media (max-width: 320px) {
              font-size: 14px;
            }
          }
          p {
            font-family: 700;
            font-size: 16px;
            margin-bottom: 12px;
            color: $placeHolderTextColor;
            @media (max-width: 425px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .linktag {
            font-family: 600;
            font-size: 16px;
            margin-bottom: 0px;
            color: $placeHolderTextColor;
            @media (max-width: 425px) {
              font-size: 14px;
            }
            @media (max-width: 320px) {
              font-size: 12px;
            }
          }
        }
        .appRoghtSIde {
          width: 30%;
          background-color: $primaryColor;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 425px) {
            width: 35%;
            > svg {
              width: 74%;
            }
          }
        }
      }
    }
  }
}
