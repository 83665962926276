@import "../../styles/globle.scss";
.navHeader {
  height: 80px;
  position: relative;
  background-color: #fbf7f8;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    font-size: $primary-font;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    .tryCrafterDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: all 0.5s ease-in-out;
      font-family: $primary-font;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: $primaryColor;
      }
    }
    > svg {
      @media (max-width: 1024px) {
        right: 32%;
      }
    }
  }
  .innerDiv {
    position: relative;
    height: 100%;
    border-radius: 54px 0 0 54px;
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 33px;
    z-index: 1 !important;
    @media (max-width: 1024px) {
      padding-right: 33px;
    }
    .sideDiv {
      position: absolute;
      height: 100%;
      width: 154%;
      top: 0;
      right: -154%;
      background-color: $primaryColor;
      @media (max-width: 1600px) {
        width: 66.5%;
        right: -66%;
      }
      @media (max-width: 1440px) {
        width: 50.5%;
        right: -50%;
      }
      @media (max-width: 1200px) {
        width: 42.5%;
        right: -42%;
      }
      @media (max-width: 1024px) {
        width: 17.5%;
        right: -17%;
      }
    }
  }
  @media (max-width: 834px) {
    display: none;
  }
}
