@import "../../../styles/globle.scss";
.coreTeam {
  margin-top: 200px;
  margin-bottom: 120px;
  background-color: $primaryColor;
  border-radius: 156px 0 156px 0;
  @media (max-width: 1024px) {
    padding-top: 135px;
  }
  @media (max-width: 912px) {
    padding-top: 112px;
  }
  @media (max-width: 834px) {
    padding-top: 188px;
    margin-top: 114px;
    margin-bottom: 114px;
    border-radius: 50px 0 50px 0;
  }
  @media (max-width: 425px) {
    margin-bottom: 60px;
  }
  @media (max-width: 375px) {
    margin-top: 90px;
    margin-bottom: 60px;
  }
  @media (max-width: 320px) {
    margin-bottom: 40px;
  }
  @media (max-width: 280px) {
    padding-top: 200px;
    margin-bottom: 40px;
  }
  .container {
    @media (max-width: 425px) {
      max-width: 425px;
      padding-left: 20px;
    }
  }
  .webAndMobileImg {
    display: flex;
    position: relative;
    padding-bottom: 24px;
    @media (max-width: 1024px) {
      padding-bottom: 20px;
    }
    @media (max-width: 425px) {
      overflow-x: clip;
    }
    @media (max-width: 320px) {
      padding-bottom: 0;
    }
    .divoo {
      .teamMobileImg {
        width: 105%;
        position: relative;
        margin-top: -100px;
        z-index: 999;
        @media (max-width: 1440px) {
          width: 100%;
          margin-top: -96px;
        }
        @media (max-width: 1439px) {
          width: 94%;
          margin-top: -96px;
        }
        @media (max-width: 1349px) {
          width: 86%;
          margin-top: -96px;
        }
        @media (max-width: 1280px) {
          width: 85%;
          margin-top: -111px;
        }
        @media (max-width: 1139px) {
          width: 78%;
          margin-top: -111px;
        }
        @media (max-width: 1024px) {
          width: 78%;
          margin-top: -255px;
        }
        @media (max-width: 912px) {
          width: 62%;
          margin-top: -262px;
        }
        @media (max-width: 834px) {
          width: 62%;
          margin-top: -264px;
        }
        @media (max-width: 820px) {
          width: 58%;
          margin-top: -264px;
        }
        @media (max-width: 768px) {
          width: 58%;
          margin-top: -264px;
        }
        @media (max-width: 425px) {
          width: 40%;
          margin-top: -264px;
        }
        @media (max-width: 390px) {
          width: 39%;
          margin-top: -264px;
        }
        @media (max-width: 375px) {
          width: 36%;
          margin-top: -264px;
        }

        @media (max-width: 320px) {
          width: 33%;
          top: -51px;
        }
        @media (max-width: 280px) {
          width: 37%;
          top: -51px;
        }
      }
    }
    .coreTeamMobileImg {
      position: absolute;
      top: -180px;
      right: 0;
      border-radius: 27px;
      width: 78%;
      @media (max-width: 1440px) {
        top: -149px;
        right: 0;
        width: 77%;
      }
      @media (max-width: 1024px) {
        top: -295px;
        right: 0;
        width: 77%;
      }
      @media (max-width: 834px) {
        border-radius: 18px;
      }
      @media (max-width: 425px) {
        top: -268px;
        right: -91px;
        width: 92%;
        border-radius: 12px;
      }
      @media (max-width: 390px) {
        top: -268px;
        right: -95px;
        width: 92%;
      }

      @media (max-width: 375px) {
        top: -241px;
        right: -86px;
        width: 92%;
      }
      @media (max-width: 320px) {
        top: -254px;
        right: -73px;
        width: 92%;
      }
      @media (max-width: 280px) {
        top: -254px;
        right: -96px;
        width: 100%;
      }
    }
  }
}
h1 {
  font-family: $extra-font;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 66px;
  color: $whiteColor;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 25px;
  }
  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 22px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
    line-height: 22px;
  }
}
.btnDiv {
  padding: 26px 0 44px 0;
  @media (max-width: 768px) {
    padding: 24px 0 34px 0;
  }
  @media (max-width: 425px) {
    padding: 14px 0 28px 0;
  }
}
