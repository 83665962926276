@import "../../styles/globle.scss";

.pricing {
  margin-bottom: 90px;
  @media (max-width: 834px) {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  @media (max-width: 425px) {
    margin-bottom: 40px;
  }

  .heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    font-family: $extra-font;
    text-align: center;
    margin: 90px 0px 10px;
    color: $primaryColor;
    @media (max-width: 834px) {
      font-size: 26px;
      margin: 30px 0;
    }
    @media (max-width: 768px) {
      font-size: 28px;
      margin: 30px 0;
    }
    @media (max-width: 425px) {
      font-size: 24px;
      margin: 30px 0;
    }
  }
  h1 {
    color: $blackColor;
    text-align: center;
    font-weight: 700;
    font-size: 44px;
    line-height: 56px;
    @media (max-width: 834px) {
      font-size: 40px;
      line-height: 52px;
    }
    @media (max-width: 425px) {
      font-size: 26px;
      line-height: 38px;
    }
    @media (max-width: 320px) {
      font-size: 22px;
      line-height: 38px;
    }
  }
  .pricingCard {
    .mainDiv {
      border: 1px solid rgba(137, 137, 137, 0.43);
      border-radius: 34px;
      padding: 40px 20px;
      width: 500px;
      margin: 100px 20px 100px auto;
      @media (max-width: 1024px) {
        width: 400px;
        margin: 100px 20px 100px auto;
      }
      @media (max-width: 834px) {
        width: 340px;
        margin: 90px 20px 90px auto;
      }
      @media (max-width: 425px) {
        width: 100%;
        margin: 53px 0;
      }
      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 37px;
        font-family: $extra-font;
        margin-bottom: 20px;
      }
      > p {
        font-size: 18px;
        font-family: $extra-font;
        color: $placeHolderTextColor;
        margin-bottom: 40px;
        line-height: 28px;
        @media (max-width: 1024px) {
          font-size: 16px;
        }
      }
      .tickAndText {
        padding-bottom: 40px;
        border-bottom: 1px solid #424242;
        P {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 16px;
          font-weight: 600;
          font-family: $extra-font;
          color: #424242;
          @media (max-width: 1024px) {
            font-size: 14px;
          }
        }
      }
      h1 {
        line-height: 142px;
        font-size: 50px;
        color: $primaryColor;
        @media (max-width: 1024px) {
          font-size: 40px;
        }
        span {
          font-size: 90px;
          @media (max-width: 1024px) {
            font-size: 80px;
          }
        }
      }

      .btnOfPricing {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .contactSaleBtn {
        background-color: #008c72 !important;
        border: 1px solid #008c72 !important;
        height: 50px !important;
        font-size: 18px !important;
        line-height: 36px;
        color: $whiteColor;
        padding: 0px 24px !important;
        font-weight: 600;
        font-family: $extra-font;
        border-radius: 10px !important;
        &:hover {
          color: $whiteColor !important;
        }
        @media (max-width: 768px) {
          padding: 0px 20px;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    .onthermainDiv {
      margin: 100px auto 100px 20px;
      @media (max-width: 1024px) {
        width: 400px;
        margin: 100px auto 100px 20px;
      }
      @media (max-width: 834px) {
        width: 340px;
        margin: 90px 20px 90px auto;
      }
      @media (max-width: 425px) {
        width: 100%;
        margin: 0 0 53px 0;
      }
    }
  }
}
