@import "../../styles/globle.scss";

.privacyPolicy {
  margin-bottom: 90px;
  @media (max-width: 834px) {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
}
.heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  font-family: $extra-font;
  text-align: center;
  margin: 50px 0;
  @media (max-width: 834px) {
    font-size: 26px;
    margin: 30px 0;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    margin: 30px 0;
  }
  @media (max-width: 425px) {
    font-size: 24px;
    margin: 30px 0;
  }
}
.otherText {
  .innerOtherText {
    margin-left: 35px;
    margin-bottom: 20px;
    @media (max-width: 834px) {
      margin-left: 0px;
    }
    @media (max-width: 768px) {
      margin-left: 0px;
    }
    ul {
      @media (max-width: 834px) {
        margin-left: 0px;
        list-style: none;
      }
      @media (max-width: 768px) {
        margin-left: 0px;
        list-style: none;
      }
      li {
        @media (max-width: 834px) {
          margin-left: 0px;
          list-style: none;
        }
        @media (max-width: 768px) {
          margin-left: 0px;
          list-style: none;
        }
        li {
          margin-left: 35px;
          @media (max-width: 834px) {
            margin-left: 0px;
            list-style: none;
          }
          @media (max-width: 768px) {
            margin-left: 0px;
            list-style: none;
          }
        }
        .limarginBottom {
          margin-bottom: 20px;
        }
        span {
          text-decoration-line: underline;
        }
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        font-family: $extra-font;
        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (max-width: 834px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  .otherInformationPionts {
    margin-left: 70px;
    margin-bottom: 20px;
    @media (max-width: 834px) {
      margin-left: 0px;
      list-style: none;
    }
    @media (max-width: 768px) {
      margin-left: 0px;
      list-style: none;
    }
    ol {
      margin-bottom: 20px;
      @media (max-width: 834px) {
        margin-left: 0px;
        list-style: none;
      }
      @media (max-width: 768px) {
        margin-left: 0px;
        list-style: none;
      }
      li {
        @media (max-width: 834px) {
          margin-left: 0px;
          list-style: none;
        }
        @media (max-width: 768px) {
          margin-left: 0px;
          list-style: none;
        }
        ul {
          margin-left: 33px;
          @media (max-width: 834px) {
            margin-left: 0px;
            list-style: none;
          }
          @media (max-width: 768px) {
            margin-left: 0px;
            list-style: none;
          }
        }
        span {
          text-decoration-line: underline;
        }
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        font-family: $extra-font;
        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (max-width: 834px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    font-family: $extra-font;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 14px;
    }
    @media (max-width: 834px) {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
  .marginBottom {
    margin-bottom: 40px;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    font-family: $extra-font;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      font-size: 16px;
      margin-bottom: 14px;
    }
    @media (max-width: 834px) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
}
