@import "../../styles/globle.scss";
.scheduleNow {
  margin: 110px auto;
  @media (max-width: 1440px) {
    margin: 50px auto;
  }
  .headings {
    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;
      color: $blackColor;
      font-family: $primary-font !important;
      @media (max-width: 1440px) {
        font-size: 38px;
      }
      @media (max-width: 1024px) {
        font-size: 34px;
      }
      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 76px;
      }
      @media (max-width: 425px) {
        font-size: 28px;
        line-height: 28px;
      }
    }
    h1 {
      padding-right: 200px;
      font-weight: 600;
      font-size: 100px;
      line-height: 96px;
      color: $primaryColor;
      margin-bottom: 38px;
      font-family: $primary-font !important;
      @media (max-width: 1440px) {
        font-size: 90px;
        padding-right: 250px;
        line-height: 84px;
        margin-bottom: 38px;
      }
      @media (max-width: 1024px) {
        font-size: 80px;
        line-height: 72px;
        padding-right: 0px;
        margin-bottom: 26px;
      }
      @media (max-width: 768px) {
        font-size: 90px;
        line-height: 72px;
        padding-right: 0px;
        margin-bottom: 52px;
      }
      @media (max-width: 425px) {
        font-size: 64px;
        line-height: 62px;
        margin-bottom: 26px;
        padding-right: 0;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $blackColor;
      margin-top: 6px;
      font-family: $primary-font !important;
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 18px;
      }
      @media (max-width: 425px) {
        display: none;
      }
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      margin-bottom: 50px;
    }
    @media (max-width: 768px) {
      margin-right: 74px;
    }
  }
  .formDivMain {
    padding: 52px 0 !important;
    background: rgba(140, 0, 25, 0.04) !important;
    border-radius: 30px !important;
    @media (max-width: 1024px) {
      padding: 40px 0 !important;
      padding-bottom: 26px !important;
    }
    @media (max-width: 425px) {
      padding: 32px 0 !important;
      padding-bottom: 26px !important;
    }
    .formdiv {
      display: flex !important;
      padding: 0 60px;
      flex-direction: column;
      @media (max-width: 1440px) {
        padding: 0 50px;
        justify-content: space-between;
        gap: 0px;
      }
      @media (max-width: 1024px) {
        justify-content: space-between;
        gap: 0px;
      }
      @media (max-width: 425px) {
        padding: 0 24px;
      }
      h2 {
        font-weight: 600;
        font-size: 38px;
        line-height: 52px;
        color: $blackColor;
        padding-bottom: 10px;
        font-family: $primary-font !important;
        @media (max-width: 1440px) {
          font-size: 28px;
        }
        @media (max-width: 1024px) {
          font-size: 32px;
          padding-bottom: 10px;
        }
        @media (max-width: 768px) {
          font-size: 38px;
        }
        @media (max-width: 425px) {
          font-size: 30px;
          line-height: 42px;
        }
      }
    }
    > h2 {
      font-weight: 600;
      font-size: 38px;
      line-height: 52px;
      color: $blackColor;
      padding: 0 60px;
      padding-bottom: 34px;
      font-family: $primary-font !important;
      @media (max-width: 1440px) {
        font-size: 28px;
        padding: 0 56px;
        padding-bottom: 32px;
      }
      @media (max-width: 1024px) {
        font-size: 32px;
        padding: 0 55px;
        padding-bottom: 20px;
      }
      @media (max-width: 768px) {
        font-size: 40px;
        padding: 0 52px;
        padding-bottom: 32px;
      }
      @media (max-width: 425px) {
        font-size: 32px;
        padding: 0 26px;
        padding-bottom: 20px;
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #363535;
      padding: 0 60px;
      padding-bottom: 36px;
      font-family: $primary-font !important;
      @media (max-width: 1440px) {
        padding: 0 60px;
        padding-bottom: 42px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        padding: 0 55px;
        padding-bottom: 30px;
      }
      @media (max-width: 768px) {
        padding-bottom: 20px;
      }
      @media (max-width: 425px) {
        padding: 0 26px;
        padding-bottom: 20px;
      }
    }
    input {
      width: 100%;
      border-radius: 54px;
      padding: 14px 40px;
      font-size: 20px;
      font-weight: 500;
      font-family: $primary-font !important;
      &:hover {
        border-color: $primaryColor !important;
      }
      &:focus {
        border-color: $primaryColor !important;
      }
      @media (max-width: 1440px) {
        width: 100%;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 425px) {
        width: 100%;
        height: 50px !important;
        font-size: 16px;
      }
      &::placeholder {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 23px;
        color: #898989 !important;
        font-family: $primary-font !important;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    textarea {
      width: 100%;
      border-radius: 30px;
      padding: 20px;
      font-size: 20px;
      font-weight: 500;
      font-family: $primary-font !important;
      &:hover {
        border-color: $primaryColor !important;
      }
      &:focus {
        border-color: $primaryColor !important;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      &::placeholder {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 23px;
        color: #898989 !important;
        font-family: $primary-font !important;
        @media (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    .responsiveBtn {
      @media (max-width: 425px) {
        justify-content: space-between;
      }
    }
  }
}
