@import "../../styles/globle.scss";

.container {
}
.usecase-container {
  max-width: 1920px;
  margin: 0 0 0 278px;
  @media (max-width: 1600px) {
    max-width: 1600px;
    margin: 0 0 0 128px;
  }
  @media (max-width: 1440px) {
    margin: 0 0 0 98px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 0 38px;
  }
  @media (max-width: 426px) {
    margin: 0 30px;
  }
  .usecase-text-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 50px;
    font-family: $extra-font;
    font-size: 18px;
    color: #1d1c1d;
    @media (max-width: 1440px) {
      font-size: 16px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      gap: 14px;
      margin-top: 28px;
    }
    @media (max-width: 834px) {
      font-size: 14px;
      gap: 14px;
      margin-top: 28px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      gap: 14px;
      margin-top: 28px;
    }
    @media (max-width: 425px) {
      margin-top: 0px;
    }

    .usecase-title {
      font-weight: 700;
      color: #8c0019;
      font-size: 30px;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 834px) {
        font-size: 20px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .usecase-topic-container {
      display: flex;
      flex-direction: column;
      gap: 18px;
      @media (max-width: 1024px) {
        gap: 14px;
      }
      @media (max-width: 834px) {
        gap: 14px;
      }
      @media (max-width: 768px) {
        gap: 14px;
      }
      .usecase-topic-title {
        font-weight: 700;
      }
    }
  }
  .usecase-gif-container {
    background-color: #f1efef;
    background-size: 500px;
    border-radius: 56px 0px 0px 56px;
    padding: 48px 40px 48px 0px;
    display: flex;
    height: fit-content;
    @media (max-width: 1024px) {
      border-radius: 36px 0px 0px 36px;
      padding: 28px 0 28px 0px;
    }
    @media (max-width: 834px) {
      border-radius: 36px 0px 0px 36px;
      padding: 28px 0 28px 0px;
    }
    @media (max-width: 768px) {
      border-radius: 36px 0px 0px 36px;
      padding: 28px 0 28px 0px;
    }
    @media (max-width: 425px) {
      background-color: $whiteColor;
    }
    .usecase-gif {
      border: 0.5px solid rgba(137, 137, 137, 0.61);
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 10px;
      transform: translate(-236px, 0px);
      width: 100%;
      object-fit: contain;
      @media (max-width: 1600px) {
        transform: translate(-88px, 0px);
      }
      @media (max-width: 1440px) {
        transform: translate(-54px, 0px);
      }
      @media (max-width: 1204px) {
        transform: translate(-38px, 0px);
      }
      @media (max-width: 834px) {
        transform: translate(-28px, 0px);
      }
      @media (max-width: 768px) {
        transform: translate(-28px, 0px);
      }
      @media (max-width: 425px) {
        transform: inherit;
      }
    }
  }
}
