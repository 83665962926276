@import "./styles/globle.scss";

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: $whiteColor;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}
.ant-drawer {
  top: 82px !important;
  height: 100%;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer-content-wrapper {
  box-shadow: none !important;
  height: 100% !important;
}

.ant-card {
  border: 1px solid rgba(137, 137, 137, 0.43);
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
  .ant-card-body {
    padding-bottom: 60px;
    @media (max-width: 1024px) {
      padding: 12px;
      padding-bottom: 60px;
    }
    @media (max-width: 834px) {
      padding: 12px;
      padding-bottom: 40px;
    }
    @media (max-width: 425px) {
      padding: 12px;
      padding-bottom: 35px;
    }
  }
  .ant-card-cover {
    margin-top: 0px;
  }
  .ant-card-meta-title {
    font-size: 24px;
    white-space: inherit;
    font-family: $extra-font;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: 1440px) {
      font-size: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 18px;
      line-height: 27px;
    }
    @media (max-width: 834px) {
      font-size: 14px;
      line-height: 21px;
    }
    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 20px;
    }
    @media (max-width: 425px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .ant-card-meta-description {
    font-family: $extra-font;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: $blackColor;
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 834px) {
      font-size: 13px;
      line-height: 19px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media (max-width: 425px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
  .rightArrowDiv {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 21px;
    right: 0;
    padding: 0 20px;
    @media (max-width: 1024px) {
      padding: 0 12px;
    }
    @media (max-width: 834px) {
      bottom: 10px;
    }
    @media (max-width: 425px) {
      bottom: 0px;
      padding: 0 10px;
    }
    svg {
      cursor: pointer;
      @media (max-width: 834px) {
        width: 32px;
      }
      @media (max-width: 425px) {
        width: 28px;
      }
    }
  }
}
.ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
  @media (max-width: 425px) {
    margin-bottom: 6px;
  }
}
@media (min-width: 280px) {
  .container {
    max-width: 254px;
    margin: 0 auto;
  }
}
@media (min-width: 320px) {
  .container {
    max-width: 260px;
    margin: 0 auto;
  }
}
@media (min-width: 360px) {
  .container {
    max-width: 300px;
    margin: 0 auto;
  }
}
@media (min-width: 375px) {
  .container {
    max-width: 314px;
    margin: 0 auto;
  }
}
@media (min-width: 390px) {
  .container {
    max-width: 330px;
    margin: 0 auto;
  }
}
@media (min-width: 412px) {
  .container {
    max-width: 348px;
    margin: 0 auto;
  }
}
@media (min-width: 414px) {
  .container {
    max-width: 348px;
    margin: 0 auto;
  }
}
@media (min-width: 425px) {
  .container {
    max-width: 365px;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 705px;
    margin: 0 auto;
  }
}
@media (min-width: 820px) {
  .container {
    max-width: 744px;
    margin: 0 auto;
  }
}
@media (min-width: 834px) {
  .container {
    max-width: 765px;
    margin: 0 auto;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 954px;
    margin: 0 auto;
  }
}
@media (min-width: 1140px) {
  .container {
    max-width: 1040px;
    margin: 0 auto;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1040px;
    margin: 0 auto;
  }
}
@media (min-width: 1350px) {
  .container {
    max-width: 1154px;
    margin: 0 auto;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1254px;
    margin: 0 auto;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1354px;
    margin: 0 auto;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1354px;
    margin: 0 auto;
  }
}
