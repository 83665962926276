@import "../../styles/globle.scss";

.button {
  font-weight: 600;
  font-family: $primary-font;
  cursor: pointer;
  border-radius: 40px;
  transition: 0.5s all;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
}

.primaryBtn {
  font-size: 12px;
  line-height: 18px;
  height: 36px;
  background-color: $whiteColor !important;
  border: 1px solid $primaryColor !important;
  svg {
    width: 28px;
  }
  &:hover {
    color: $primaryColor !important;
  }
}
.primaryBigBtn {
  height: 50px;
  font-size: 18px;
  line-height: 26px;
  background-color: $whiteColor !important;
  border: 1px solid $primaryColor !important;
  svg {
    width: 38px;
  }
  @media (max-width: 1024px) {
    svg {
      width: 34px;
    }
  }
  @media (max-width: 834px) {
    svg {
      width: 30px;
    }
  }
  @media (max-width: 425px) {
    svg {
      width: 38px;
    }
  }
  @media (max-width: 768px) {
    height: 50px;
    padding: 0 20px;
  }
  @media (max-width: 425px) {
    height: 34px;
    padding: 0 20px;
    font-size: 12.2099px;
    line-height: 14px;
    svg {
      width: 26px;
    }
  }
  &:hover {
    color: $primaryColor !important;
  }
}

.secondaryBtn {
  background-color: #008c72;
  border: 1px solid #008c72;
  height: 50px;
  font-size: 18px;
  line-height: 36px;
  color: $whiteColor;
  padding: 0px 24px;
  &:hover {
    color: $whiteColor !important;
  }
  svg {
    width: 34px;
  }
  @media (max-width: 768px) {
    padding: 0px 20px;
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 425px) {
    width: 100%;
    justify-content: center;
    height: 48px;
    svg {
      position: absolute;
      right: 10px;
    }
  }
}
