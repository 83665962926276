.mainRowBlog {
  margin: 100px 0;
  @media (max-width: 1440px) {
    margin: 90px 0;
  }
  @media (max-width: 425px) {
    margin: 53px 0;
  }
  > div {
    @media (max-width: 390px) {
      &:nth-child(odd) {
        padding-right: 7px !important;
      }
      &:nth-child(even) {
        padding-left: 7px !important;
      }
    }
  }
  .columOfBlog {
    margin-bottom: 90px;
  }
}
