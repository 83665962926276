@import "../../../styles/globle.scss";

.herosection {
  padding-top: 120px;
  padding-bottom: 46px;
  @media (max-width: 1440px) {
    padding: 50px 0px;
  }
  @media (max-width: 425px) {
    padding: 30px 0px;
    padding-bottom: 0;
  }
  h2 {
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 48px;
    color: $blackColor;
    text-align: center;
    @media (max-width: 1440px) {
      font-size: 38px;
    }
    @media (max-width: 1024px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 40px;
    }
    @media (max-width: 425px) {
      font-size: 28px;
      line-height: 28px;
    }
  }
  h1 {
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 94px;
    letter-spacing: 0.02em;
    color: $primaryColor;
    text-align: center;
    margin: 18px 0;
    @media (max-width: 1440px) {
      font-size: 84px;
    }
    @media (max-width: 1024px) {
      font-size: 80px;
      margin: 18px 0;
    }
    @media (max-width: 834px) {
      font-size: 76px;
      line-height: 84px;
      margin: 16px 0;
    }
    @media (max-width: 768px) {
      font-size: 74px;
      line-height: 84px;
      margin: 14px 0;
    }
    @media (max-width: 425px) {
      font-size: 38px;
      line-height: 26px;
    }
  }
  p {
    font-family: $extra-font;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: $textColor;
    margin: 30px 212px;
    margin-top: 0;
    text-align: center;
    @media (max-width: 1440px) {
      font-size: 16px;
      margin: 26px 212px;
    }
    @media (max-width: 1350px) {
      margin: 26px 158px;
    }
    @media (max-width: 1200px) {
      margin: 26px 100 px;
    }
    @media (max-width: 1024px) {
      margin: 0px 58px 30px;
    }
    @media (max-width: 834px) {
      font-size: 16px;
      line-height: 25px;
      margin: 0px 60px 30px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 27px;
      margin: 14px 14px 32px;
      padding: 0;
    }
    @media (max-width: 425px) {
      font-size: 14px;
      line-height: 22px;
      margin: 14px 0px 30px;
    }
  }
  .inputAndBtn {
    @media (max-width: 425px) {
      > div {
        display: block !important;
      }
    }
    .formdiv {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @media (max-width: 1024px) {
        justify-content: space-between;
        gap: 12px;
      }
      @media (max-width: 425px) {
        display: block !important;
      }
    }
    input {
      height: 50px !important;
      width: 466px;
      border-radius: 54px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: 500;
      font-family: $primary-font !important;
      &:hover {
        border-color: $primaryColor !important;
      }
      &:focus {
        border-color: $primaryColor !important;
      }
      @media (max-width: 1024px) {
        width: 380px;
      }
      @media (max-width: 768px) {
        width: 400px;
      }
      @media (max-width: 540px) {
        width: 215px;
      }
      @media (max-width: 425px) {
        width: 100%;
        height: 48px !important;
      }
    }
  }
  input::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    font-family: $primary-font !important;
  }
}
