@import "../../styles/globle.scss";

.features-container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 100px auto;
  @media (max-width: 1440px) {
    margin: 95px auto;
  }
  @media (max-width: 1024px) {
    margin: 85px auto;
  }
  @media (max-width: 834px) {
    margin: 73px auto;
    gap: 73px;
  }
  @media (max-width: 768px) {
    margin: 73px auto;
    gap: 73px;
  }
  @media (max-width: 425px) {
    margin: 53px auto;
    gap: 53px;
  }
}

.features-description {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: $extra-font;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 834px) {
    font-size: 14px;
    gap: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    gap: 16px;
  }
}
