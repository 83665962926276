@import "../../styles/globle.scss";

.feature-container {
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
  .feature-text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 46px;
    padding: 0 62px;
    @media (max-width: 1024px) {
      margin-top: 24px;
      padding: 0 0px;
    }
    @media (max-width: 834px) {
      margin-top: 22px;
      gap: 16px;
    }
    @media (max-width: 768px) {
      margin-top: 22px;
      gap: 16px;
    }
    @media (max-width: 425px) {
      margin-top: 0px;
      padding: 0 0px;
    }
    .feature-text {
      font-size: 18px;
      font-family: $extra-font;
      color: #1d1c1d;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
      }
      @media (max-width: 834px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 426px) {
        font-size: 15px;
      }
    }

    .feature-title {
      font-weight: 700;
      color: #8c0019;
      font-size: 30px;
      text-transform: uppercase;
      font-family: $extra-font;
      @media (max-width: 1024px) {
        font-size: 26px;
      }
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
}
.feature-gif-container {
  background-color: #f1efef;
  border-radius: 20px;
  padding: 42px 0px;
  display: flex;
  height: fit-content;

  @media (max-width: 1024px) {
    padding: 24px 0px;
  }
  @media (max-width: 834px) {
    padding: 26px 0px;
  }
  @media (max-width: 768px) {
    padding: 22px 0px;
  }
  @media (max-width: 425px) {
    background-color: $whiteColor;
    padding: auto;
  }
  .feature-gif {
    border: 0.5px solid rgba(137, 137, 137, 0.61);
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    object-fit: contain;
    width: 105%;
    transform: translate(6%, 0);
    @media (max-width: 1024px) {
      transform: translate(5%, 0);
    }
    @media (max-width: 768px) {
      transform: translate(6%, 0);
    }
    @media (max-width: 425px) {
      width: 100%;
      transform: inherit;
    }
  }
}

.feature-container:nth-child(even) {
  flex-direction: row-reverse;
  @media (max-width: 425px) {
    flex-direction: column;
  }
  .feature-gif-container {
    @media (max-width: 425px) {
      margin: auto;
    }

    .feature-gif {
      transform: translate(-11%, 0);
      @media (max-width: 1024px) {
        transform: translate(-10%, 0);
      }
      @media (max-width: 768px) {
        transform: translate(-12%, 0);
      }
      @media (max-width: 425px) {
        transform: unset;
      }
    }
  }
}
