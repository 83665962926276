@import "../../styles/globle.scss";

.usecases-container {
  display: flex;
  flex-direction: column;
  gap: 133px;
  margin: 100px auto;
  margin-top: 50px;
  @media (max-width: 1440px) {
    margin: 90px auto;
  }
  @media (max-width: 1024px) {
    margin: 85px auto;
    gap: 93px;
  }
  @media (max-width: 834px) {
    margin: 73px auto;
    gap: 73px;
  }
  @media (max-width: 768px) {
    margin: 73px auto;
    gap: 73px;
  }
  @media (max-width: 425px) {
    margin: 51px auto;
    gap: 53px;
  }
}
